import { PageRendererProps } from 'gatsby'
import React from 'react'
import { Title } from '@/styles'
import Layout from '@/components/layout'

const NotFound: React.FunctionComponent<PageRendererProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Title>Page not found</Title>
        </Layout>
    );
}

export default NotFound